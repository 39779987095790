import { GrowthBook } from "@growthbook/growthbook"
import { type LinksFunction, type LoaderFunctionArgs, json } from "@remix-run/node"
import { Link, Links, Meta, Outlet, Scripts, ScrollRestoration, useLoaderData, useRouteError } from "@remix-run/react"
import { useLocation, useMatches } from "@remix-run/react"
import { captureRemixErrorBoundaryError, withSentry } from "@sentry/remix"
import * as Sentry from "@sentry/remix"
import { useMount } from "ahooks"
import { useEffect } from "react"
import remixicon from "remixicon/fonts/remixicon.css?url"
import { Resource } from "sst"
import { UserContext } from "~/modules/auth/context/UserContext"
import styles from "./globals.css?url"
import { authenticator } from "./modules/auth/api/auth.server"
import { Button } from "./modules/common/ui/button"
import { Label } from "./modules/common/ui/label"
import {
  type FeatureFlags,
  getServerSideGrowthBookContext,
} from "./modules/feature-flags/utils/feature-flag-init.server"
import NavBar from "./modules/layout/component/NavBar"

export const links: LinksFunction = () => [
  { rel: "stylesheet", href: styles },
  { rel: "stylesheet", href: remixicon },
]

export const loader = async ({ request }: LoaderFunctionArgs) => {
  const maybeUser = await authenticator.isAuthenticated(request)
  const gbContext = getServerSideGrowthBookContext(request)
  const gb = new GrowthBook<FeatureFlags>(gbContext)
  await gb.loadFeatures({ timeout: 1000 })
  const allFlags = gb.getFeatures()

  const evaluatedFlags = Object.keys(allFlags).reduce((acc, featureFlag) => {
    const typedFlag = featureFlag as keyof FeatureFlags
    acc[typedFlag] = gb.isOn(typedFlag)
    return acc
  }, {} as FeatureFlags)

  return json({
    user: maybeUser,
    features: evaluatedFlags,
    stage: Resource.App.stage,
    sentryDsn: Resource.SentryClientSecretDSN.value,
  })
}

function App() {
  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
      </head>
      <body>
        <OutletWithProviders />
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  )
}

export default withSentry(App)

const OutletWithProviders = () => {
  const { user, sentryDsn, stage } = useLoaderData<typeof loader>()

  useMount(() => {
    if (!Sentry.isInitialized()) {
      Sentry.init({
        dsn: sentryDsn,
        tracesSampleRate: 1,
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1,
        environment: stage,
        integrations: [
          Sentry.browserTracingIntegration({
            useEffect,
            useLocation,
            useMatches,
          }),
          Sentry.replayIntegration(),
        ],
      })
    }
  })

  return (
    <UserContext.Provider value={{ user }}>
      <Outlet />
    </UserContext.Provider>
  )
}

export function ErrorBoundary() {
  const error = useRouteError()
  console.error(error)
  captureRemixErrorBoundaryError(error)
  return (
    <html lang="en">
      <head>
        <title>Oh no!</title>
        <Meta />
        <Links />
      </head>
      <body>
        <div className="flex flex-col bg-background">
          <NavBar />
          <div className="flex-grow flex justify-center">
            <div className="p-8 rounded-lg">
              <Label className="text-4xl font-bold mb-4">Unexpected Error</Label>
              <p className="text-xl mb-4">
                An unexpected error occurred. Our team has been notified and we'll fix it as soon as possible.
              </p>
              <Link to="/" viewTransition>
                <Button>Go back to homepage</Button>
              </Link>
            </div>
          </div>
        </div>
        <Scripts />
      </body>
    </html>
  )
}
